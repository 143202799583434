<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100" v-else>
      <div class="banner" :style="{ 'background-image': `url(${require('../../../assets/images/common/top_banner_' + cond.theme_color + '.png')})` }">
        <h1>{{ cond.report_name }}</h1>
        <p>
          <span>{{ cond.subtitle }}</span>
        </p>
      </div>
      <div class="jpbj">
        <div class="warpper">
          <!-- 舆情总览 -->
          <div class="ov">
            <h1>一、舆情总览</h1>
            <el-row class="icon_list">
              <el-col :span="6">
                <div class="tit">
                  <img src="@/assets/images/common/icon/compare_icon_1.svg" alt="声量最大" width="25"><span>声量最大</span>
                </div>
                <p><strong>{{ detail.summary.count_max.name }}</strong></p>
                <p><span class="b">{{ detail.summary.count_max.count }}</span> 条</p>
              </el-col>
              <el-col :span="6">
                <div class="tit">
                  <img src="@/assets/images/common/icon/compare_icon_2.svg" alt="敏感最多" width="25"><span>敏感最多</span>
                </div>
                <p><strong>{{ detail.summary.neg_count_max.name }}</strong></p>
                <p><span class="b">{{ detail.summary.neg_count_max.count }}</span> 条</p>
              </el-col>
              <el-col :span="6">
                <div class="tit">
                  <img src="@/assets/images/common/icon/compare_icon_3.svg" alt="覆盖最广" width="25"><span>覆盖最广</span>
                </div>
                <p><strong>{{ detail.summary.distributed_max.name }}</strong></p>
                <p><span class="b">{{ detail.summary.distributed_max.count }}</span> 个媒体</p>
              </el-col>
              <el-col :span="6">
                <div class="tit">
                  <img src="@/assets/images/common/icon/compare_icon_4.svg" alt="上涨最快" width="25"><span>上涨最快</span>
                </div>
                <p><strong>{{ detail.summary.rise_max.name }}</strong></p>
                <p>波动 <span class="b">{{ detail.summary.rise_max.count }}</span></p>
              </el-col>
            </el-row>
          </div>
          <!--@end 舆情总览 -->
          <!-- 信息声量比较 -->
          <div>
            <h1>二、信息声量比较</h1>
            <p>{{ detail.count.desc }}</p>
            <div class="flex mar-t-20">
              <!-- 信息总量 -->
              <el-card class="box-card w-50 h-300">
                <h3 class="mod-title">信息总量 <el-tooltip class="item" effect="dark" content="监测时间段内的信息总量" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
                <div class="echarts_empty hide">
                  <div class="img">暂无数据</div>
                </div>
                <div class="w-100 h-250" id="info">
                </div>
              </el-card>
              <!--@end 信息总量 -->
              <!-- 敏感信息 -->
              <el-card class="box-card w-50 h-300">
                <h3 class="mod-title"><span class="red">敏感</span>信息 <el-tooltip class="item" effect="dark" content="监测时间段内的敏感信息数量" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
                <div class="echarts_empty hide">
                  <div class="img">暂无数据</div>
                </div>
                <div class="w-100 h-250" id="neg-info">
                </div>
              </el-card>
              <!--@end 敏感信息 -->
            </div>
            <div class="flex mar-t-20">
              <!-- 信息走势 -->
              <el-card class="box-card w-50 h-300 ov-v">
                <h3 class="mod-title">信息走势 <el-tooltip class="item" effect="dark" content="监测时间段内信息总量随时间变化的趋势" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
                <div class="echarts_empty hide">
                  <div class="img">信息走势</div>
                </div>
                <div class="w-100 h-250" id="info-trend">
                </div>
              </el-card>
              <!--@end 信息走势 -->
              <!-- 敏感信息走势 -->
              <el-card class="box-card w-50 h-300 ov-v">
                <h3 class="mod-title"><span class="red">敏感</span>信息走势 <el-tooltip class="item" effect="dark" content="监测时间段内敏感信息数量随时间变化的趋势" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
                <div class="echarts_empty hide">
                  <div class="img">暂无数据</div>
                </div>
                <div class="w-100 h-250" id="neg-info-trend">
                </div>
              </el-card>
              <!--@end 敏感信息走势 -->
            </div>
          </div>
          <!-- 信息声量比较 -->
          <!-- 三、传播内容比较 -->
          <div class="ov mar-t-20">
            <h1>三、传播内容比较</h1>
            <p>{{ detail.content.desc }}</p>
            <!--热门关键词  -->
            <el-tabs type="border-card" class="ov mar-t-20">
              <el-tab-pane>
                <span slot="label">热门关键词 <el-tooltip class="item" effect="dark" content="对信息内容进行分词，展示被提及次数最高的关键词" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></span>
                <div class="flex">
                  <div class="jzxx2 rmgjc echarts-box">
                    <el-row type="flex" justify="space-around">
                      <el-col :span="8" v-for="(item, index) in detail.content.words" :key="index" v-if="index <= 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="mar-t-20 flex">
                          <div class="mod-echarts-right word_cloud" v-show="item.data.length >= 1"></div>
                          <div class="mod-list" v-show="item.data.length >= 1">
                            <ul>
                              <li v-for="(word, index) in item.data" v-if="index <= 9" :key="index">
                                <span class="num bg-555" v-if="index == 0">1</span>
                                <span class="num bg-689" v-else-if="index == 1">2</span>
                                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                                <a class="mod-list-title1" :title="word.word">{{ word.word }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="mar-t-10" v-if="detail.content.words.length > 3">
                      <el-col :span="8" v-for="(item, index) in detail.content.words" :key="index" v-if="index > 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="mar-t-20 flex">
                          <div class="mod-echarts-right word_cloud" v-show="item.data.length >= 1"></div>
                          <div class="mod-list" v-show="item.data.length >= 1">
                            <ul>
                              <li v-for="(word, index) in item.data" v-if="index <= 9" :key="index">
                                <span class="num bg-555" v-if="index == 0">1</span>
                                <span class="num bg-689" v-else-if="index == 1">2</span>
                                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                                <a class="title" :title="word.word">{{ word.word }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label">上升关键词 <el-tooltip class="item" effect="dark" placement="bottom">
                  <i class="iconfont icon-tishi"></i>
                  <div slot="content">对信息内容进行分词，展示监测时间内被提及<br/>次数增加最多的关键词</div>
                  </el-tooltip></span>
                <div class="flex ssgjc">
                  <div class="jzxx2">
                    <el-row :gutter="20" type="flex" justify="space-around">
                      <el-col :span="8" v-for="(item, index) in detail.content.rise_words" :key="index" v-if="index <= 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="tab-content mod-list" v-show="item.data.length >= 1">
                          <ul>
                            <li>
                              <h3>
                                <span class="num-tit">序号</span>
                                <span class="mod-list-title2">关键词</span>
                                <span class="tag">上升</span>
                                <span class="tag">新增条数</span>
                              </h3>
                            </li>
                            <li v-for="(row, index) in item.data" v-if="index <= 9" :key="index">
                             <span class="num bg-555" v-if="index == 0">1</span>
                              <span class="num bg-689" v-else-if="index == 1">2</span>
                              <span class="num bg-8ea" v-else-if="index == 2">3</span>
                              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                              <a class="mod-list-title1" :title="row.word">{{row.word}}</a>
                              <el-progress
                                :text-inside="true"
                                :stroke-width="14"
                                :percentage="row.percent"
                                :show-text="false">
                              </el-progress>
                              <span class="tag">{{ row.rise }}%</span>
                              <span class="tag">{{ row.count }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mar-t-10" v-if="detail.content.rise_words.length > 3">
                      <el-col :span="8" v-for="(item, index) in detail.content.rise_words" :key="index" v-if="index > 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="tab-content mod-list" v-show="item.data.length >= 1">
                          <ul>
                            <li>
                              <h3>
                                <span class="num-tit">序号</span>
                                <span class="mod-list-title2">关键词</span>
                                <span class="tag">上升</span>
                                <span class="tag">新增条数</span>
                              </h3>
                            </li>
                            <li v-for="(row, index) in item.data" v-if="index <= 9" :key="index">
                              <span class="num bg-555" v-if="index == 0">1</span>
                              <span class="num bg-689" v-else-if="index == 1">2</span>
                              <span class="num bg-8ea" v-else-if="index == 2">3</span>
                              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                              <a class="mod-list-title1" :title="row.word">{{row.word}}</a>
                              <el-progress
                                :text-inside="true"
                                :stroke-width="14"
                                :percentage="row.percent"
                                :show-text="false">
                              </el-progress>
                              <span class="tag">{{ row.rise }}%</span>
                              <span class="tag">{{ row.count }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label">高频正面词 <el-tooltip class="item" effect="dark" placement="bottom">
                  <i class="iconfont icon-tishi"></i>
                  <div slot="content">对信息内容进行情感分析，提取非敏感信息中的正面关键词，<br/>展示被提及次数最多的正面关键词</div>
                  </el-tooltip></span>
                <div class="flex ssgjc gpzmc">
                  <div class="jzxx2">
                    <el-row :gutter="20" type="flex" justify="space-around">
                      <el-col :span="8" v-for="(item, index) in detail.content.pos_words" :key="index"  v-if="index <= 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="mod-list" v-show="item.data.length >= 1">
                          <ul>
                            <li>
                              <h3>
                                <span class="num-tit">序号</span>
                                <span class="mod-list-title2">关键词</span>
                                <span class="tag">信息数</span>
                              </h3>
                            </li>
                            <li v-for="(row, index) in item.data" :key="index" v-if="index <= 9">
                              <span class="num bg-555" v-if="index == 0">1</span>
                              <span class="num bg-689" v-else-if="index == 1">2</span>
                              <span class="num bg-8ea" v-else-if="index == 2">3</span>
                              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                              <a class="mod-list-title1" :title="row.word">{{row.word}}</a>
                              <el-progress
                                :text-inside="true"
                                :stroke-width="14"
                                :percentage="row.percent"
                                :show-text="false">
                              </el-progress>
                              <span class="tag">{{ row.count }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mar-t-10" v-if="detail.content.pos_words.length > 3">
                      <el-col :span="8" v-for="(item, index) in detail.content.pos_words" :key="index" v-if="index > 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="mod-list" v-show="item.data.length >= 1">
                          <ul>
                            <li>
                              <h3>
                                <span class="num-tit">序号</span>
                                <span class="mod-list-title2">关键词</span>
                                <span class="tag">信息数</span>
                              </h3>
                            </li>
                            <li v-for="(row, index) in item.data" :key="index" v-if="index <= 9">
                              <span class="num bg-555" v-if="index == 0">1</span>
                              <span class="num bg-689" v-else-if="index == 1">2</span>
                              <span class="num bg-8ea" v-else-if="index == 2">3</span>
                              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                              <a class="mod-list-title1" :title="row.word">{{row.word}}</a>
                              <el-progress
                                :text-inside="true"
                                :stroke-width="14"
                                :percentage="row.percent"
                                :show-text="false">
                              </el-progress>
                              <span class="tag">{{ row.count }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label">高频负面词 <el-tooltip class="item" effect="dark" placement="bottom"><i class="iconfont icon-tishi"></i>
                 <div slot="content">对信息内容进行情感分析，提取敏感信息中的负面关键词，<br/>展示被提及次数最多的负面关键词</div>
                </el-tooltip></span>
                <div class="flex ssgjc gpzmc">
                  <div class="jzxx2">
                    <el-row :gutter="20" type="flex" justify="space-around">
                      <el-col :span="8" v-for="(item, index) in detail.content.neg_words" :key="index"  v-if="index <= 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="mod-list" v-show="item.data.length >= 1">
                          <ul>
                            <li>
                              <h3>
                                <span class="num-tit">序号</span>
                                <span class="mod-list-title2">关键词</span>
                                <span class="tag">信息数</span>
                              </h3>
                            </li>
                            <li v-for="(row,index) in item.data" v-if="index <= 9" :key="index">
                              <span class="num bg-555" v-if="index == 0">1</span>
                              <span class="num bg-689" v-else-if="index == 1">2</span>
                              <span class="num bg-8ea" v-else-if="index == 2">3</span>
                              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                              <a class="mod-list-title1" :title="row.word">{{row.word}}</a>
                              <el-progress
                                :text-inside="true"
                                :stroke-width="14"
                                :percentage="row.percent"
                                :show-text="false">
                              </el-progress>
                              <span class="tag">{{ row.count }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mar-t-10" v-if="detail.content.neg_words.length > 3">
                      <el-col :span="8" v-for="(item, index) in detail.content.neg_words" :key="index" v-if="index > 2">
                        <div class="gjcz">{{ item.key }}</div>
                        <div class="mod-list" v-show="item.data.length >= 1">
                          <ul>
                            <li>
                              <h3>
                                <span class="num-tit">序号</span>
                                <span class="mod-list-title2">关键词</span>
                                <span class="tag">信息数</span>
                              </h3>
                            </li>
                            <li v-for="(row,index) in item.data" v-if="index <= 9" :key="index">
                              <span class="num bg-555" v-if="index == 0">1</span>
                              <span class="num bg-689" v-else-if="index == 1">2</span>
                              <span class="num bg-8ea" v-else-if="index == 2">3</span>
                              <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                              <a class="mod-list-title1" :title="row.word">{{row.word}}</a>
                              <el-progress
                                :text-inside="true"
                                :stroke-width="14"
                                :percentage="row.percent"
                                :show-text="false">
                              </el-progress>
                              <span class="tag">{{ row.count }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="echarts_empty" v-show="item.data.length < 1">
                          <div class="img"></div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <!--@热门关键词  -->
            <!-- 焦点信息 -->
            <el-tabs type="border-card" class="ov mar-t-20 jdxxbj">
              <el-tab-pane>
                <span slot="label">焦点信息 <el-tooltip class="item" effect="dark" content="监测时间段内，和主题相关度高且较为重要的信息" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></span>
                <el-row :gutter="20">
                  <el-col :span="12" v-for="(item, index) in detail.content.msg" :key="index">
                    <div class="gjcz">{{ item.key }}</div>
                    <div class="mod-list" v-show="item.data.length >= 1">
                      <ul>
                        <li v-for="(row,index) in item.data" :key="index">
                          <div class="ov-h">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-else-if="index == 1">2</span>
                            <span class="num bg-8ea" v-else-if="index == 2">3</span>
                            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                            <el-button type="primary" class="mg" v-if="row.emotion === 'negative'">敏感</el-button>
                            <el-button type="primary" class="fmg" v-if="row.emotion === 'positive'">非敏感</el-button>
                            <a :href="row.source_url" target="_blank" class="mod-list-title2">{{row.title}}</a>
                            <span class="zb">（{{ row.percent }}%）</span>
                          </div>
                          <p class="flex">
                            <span>
                              <strong>相关报道数:</strong>
                              {{ row.similar_count }}条
                            </span>
                            <span>
                              <strong>来源:</strong>
                              {{row.category}}
                            </span>
                            <span>
                              <strong>代表媒体:</strong>
                              <a>{{ row.author_str ? row.author_str : row.source_name_str }}</a>
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="echarts_empty" v-show="item.data.length < 1">
                      <div class="img"></div>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label">焦点正面信息 <el-tooltip class="item" effect="dark" placement="bottom"><i class="iconfont icon-tishi"></i>
                <div slot="content">对信息内容进行情感分析，展示和主题相关度高<br/>且较为重要的正面信息</div>
                </el-tooltip></span>
                <el-row :gutter="20">
                  <el-col :span="12" v-for="(item, index) in detail.content.pos_msg" :key="index">
                    <div class="gjcz">{{ item.key }}</div>
                    <div class="mod-list" v-show="item.data.length >= 1">
                      <ul>
                        <li v-for="(row,index) in item.data" :key="index">
                          <div class="ov-h">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-else-if="index == 1">2</span>
                            <span class="num bg-8ea" v-else-if="index == 2">3</span>
                            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                            <el-button type="primary" class="mg" v-if="row.emotion === 'negative'">敏感</el-button>
                            <el-button type="primary" class="fmg" v-if="row.emotion === 'positive'">非敏感</el-button>
                            <a :href="row.source_url" target="_blank" class="mod-list-title2">{{row.title}}</a>
                            <span class="zb">（{{ row.percent }}%）</span>
                          </div>
                          <p class="flex">
                            <span>
                              <strong>相关报道数:</strong>
                              {{ row.similar_count }}条
                            </span>
                            <span>
                              <strong>来源:</strong>
                              {{row.category}}
                            </span>
                            <span>
                              <strong>代表媒体:</strong>
                              <a>{{ row.author_str ? row.author_str : row.source_name_str }}</a>
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="echarts_empty" v-show="item.data.length < 1">
                      <div class="img"></div>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label">焦点负面信息 <el-tooltip class="item" effect="dark" placement="bottom"><i class="iconfont icon-tishi"></i>
                <div slot="content">对信息内容进行情感分析，展示和主题相关度高<br/>且较为重要的负面信息</div>
                </el-tooltip></span>
                <el-row :gutter="20">
                  <el-col :span="12" v-for="(item, index) in detail.content.neg_msg" :key="index">
                    <div class="gjcz">{{ item.key }}</div>
                    <div class="mod-list" v-show="item.data.length >= 1">
                      <ul>
                        <li v-for="(row,index) in item.data" :key="index">
                          <div class="ov-h">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-else-if="index == 1">2</span>
                            <span class="num bg-8ea" v-else-if="index == 2">3</span>
                            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                            <el-button type="primary" class="mg" v-if="row.emotion === 'negative'">敏感</el-button>
                            <el-button type="primary" class="fmg" v-if="row.emotion === 'positive'">非敏感</el-button>
                            <a :href="row.source_url" target="_blank" class="mod-list-title2">{{row.title}}</a>
                            <span class="zb">（{{ row.percent }}%）</span>
                          </div>
                          <p class="flex">
                            <span>
                              <strong>相关报道数:</strong>
                              {{ row.similar_count }}条
                            </span>
                            <span>
                              <strong>来源:</strong>
                              {{row.category}}
                            </span>
                            <span>
                              <strong>代表媒体:</strong>
                              <a>{{ row.author_str ? row.author_str : row.source_name_str }}</a>
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div class="echarts_empty" v-show="item.data.length < 1">
                      <div class="img"></div>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
            <!--@end 焦点信息 -->
            <h1>四、传播媒体比较</h1>
            <p>{{ detail.media.desc }}</p>
            <!-- 媒体类型分布 -->
            <el-card class="box-card mar-t-20">
              <h3 class="mod-title">媒体类型分布 <el-tooltip class="item" effect="dark" content="相关信息在不同媒体类型的信息量" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
              <el-row :gutter="20" class="mar-t-20" type="flex" justify="space-around">
                <el-col :span="8" v-for="(item, index) in detail.media.media_distributed" :key="index" v-if="index <= 2">
                  <div class="gjcz">{{ item.key }}</div>
                  <div class="echarts_empty" v-show="item.data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="w-100 h-250 map-chart" v-show="item.data.length >= 1">
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="mar-t-20" v-if="detail.media.media_distributed.length > 3">
                <el-col :span="8" v-for="(item, index) in detail.media.media_distributed" :key="index" v-if="index > 2">
                  <div class="gjcz">{{ item.key }}</div>
                  <div class="echarts_empty" v-show="item.data.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="w-100 h-250 map-chart" v-show="item.data.length >= 1">
                  </div>
                </el-col>
              </el-row>
            </el-card>
            <!--@end 媒体类型分布 -->
            <!-- TOP10媒体来源 -->
            <el-card class="box-card mar-t-20">
              <h3 class="mod-title"><span class="red">TOP10</span>媒体来源 <el-tooltip class="item" effect="dark" content="监测时间段内，发布相关信息最多的媒体" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
              <div class="flex ssgjc gpzmc">
                <div class="jzxx2">
                  <el-row :gutter="20" type="flex" class="pad-lr-20" justify="space-around">
                    <el-col :span="8" v-for="(item, index) in detail.media.media_top10" :key="index" v-if="index <= 2">
                      <div class="gjcz">{{ item.key }}</div>
                      <div class="mod-list" v-if="item.data.length >= 1">
                        <ul>
                          <li>
                            <h3>
                              <span class="num-tit">序号</span>
                              <span class="mod-list-title2">媒体</span>
                              <span class="tag">占比</span>
                            </h3>
                          </li>
                          <li v-for="(row, index) in item.data" :key="index">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-else-if="index == 1">2</span>
                            <span class="num bg-8ea" v-else-if="index == 2">3</span>
                            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="row.name">{{row.name}}</a>
                            <el-progress
                              :text-inside="true"
                              :stroke-width="14"
                              :percentage="row.percent"
                              :show-text="false">
                            </el-progress>
                            <span class="tag">{{ row.percent_total }}%</span>
                          </li>
                        </ul>
                      </div>
                      <div class="echarts_empty" v-if="item.data.length < 1">
                        <div class="img"></div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="pad-lr-20 mar-t-10" v-if="detail.media.media_top10.length > 3">
                    <el-col :span="8" v-for="(item, index) in detail.media.media_top10" :key="index" v-if="index > 2">
                      <div class="gjcz">{{ item.key }}</div>
                      <div class="mod-list" v-if="item.data.length >= 1">
                        <ul>
                          <li>
                            <h3>
                              <span class="num-tit">序号</span>
                              <span class="mod-list-title2">媒体</span>
                              <span class="tag">占比</span>
                            </h3>
                          </li>
                          <li v-for="(row, index) in item.data" :key="index">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-else-if="index == 1">2</span>
                            <span class="num bg-8ea" v-else-if="index == 2">3</span>
                            <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="row.name">{{row.name}}</a>
                            <el-progress
                              :text-inside="true"
                              :stroke-width="14"
                              :percentage="row.percent"
                              :show-text="false">
                            </el-progress>
                            <span class="tag">{{ row.percent }}%</span>
                          </li>
                        </ul>
                      </div>
                      <div class="echarts_empty" v-if="item.data.length < 1">
                        <div class="img"></div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
            <!--@end TOP10媒体来源 -->
            <!-- 关键微博博主 -->
            <el-card class="box-card mar-t-20 bzbj">
              <h3 class="mod-title">关键微博博主 <el-tooltip class="item" effect="dark" content="监测时间段内，相关微博转发和评论数量最多的微博博主" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
              <div class="mar-t-20">
                <el-table :data="wbData" style="width: 100%">
                  <el-table-column :label="detail.media.media_weibo[0].key" header-align="center" v-if="detail.media.media_weibo.length >= 1">
                    <el-table-column prop="name1" label="博主" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name1 }}</span>
                        <i class="type_ico v_red" v-if="scope.row.usertype1 === '红V'"></i>
                        <i class="type_ico v_blue" v-if="scope.row.usertype1 === '蓝V'"></i>
                        <i class="type_ico v_orange" v-if="scope.row.usertype1 === '金V'"></i>
                        <i class="type_ico v_pink" v-if="scope.row.usertype1 === '微博女郎'"></i>
                        <i class="type_ico v_talent_show" v-if="scope.row.usertype1 === '达人'"></i>
                        <i class="type_ico" v-else></i>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count1" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_weibo[1].key" header-align="center" v-if="detail.media.media_weibo.length >= 2">
                    <el-table-column prop="name2" label="博主" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name2 }}</span>
                        <i class="type_ico v_red" v-if="scope.row.usertype2 === '红V'"></i>
                        <i class="type_ico v_blue" v-if="scope.row.usertype2 === '蓝V'"></i>
                        <i class="type_ico v_orange" v-if="scope.row.usertype2 === '金V'"></i>
                        <i class="type_ico v_pink" v-if="scope.row.usertype2 === '微博女郎'"></i>
                        <i class="type_ico v_talent_show" v-if="scope.row.usertype2 === '达人'"></i>
                        <i class="type_ico" v-else></i>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count2" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_weibo[2].key" header-align="center" v-if="detail.media.media_weibo.length >= 3">
                    <el-table-column prop="name3" label="博主" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name3 }}</span>
                        <i class="type_ico v_red" v-if="scope.row.usertype3 === '红V'"></i>
                        <i class="type_ico v_blue" v-if="scope.row.usertype3 === '蓝V'"></i>
                        <i class="type_ico v_orange" v-if="scope.row.usertype3 === '金V'"></i>
                        <i class="type_ico v_pink" v-if="scope.row.usertype3 === '微博女郎'"></i>
                        <i class="type_ico v_talent_show" v-if="scope.row.usertype3 === '达人'"></i>
                        <i class="type_ico" v-else></i>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count3" label="条数" align="center"></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
              <div class="mar-t-20" v-if="detail.media.media_weibo.length >= 4">
                <el-table :data="wbData" style="width: 100%">
                  <el-table-column :label="detail.media.media_weibo[3].key" header-align="center">
                    <el-table-column prop="name4" label="博主" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name4 }}</span>
                        <i class="type_ico v_red" v-if="scope.row.usertype4 === '红V'"></i>
                        <i class="type_ico v_blue" v-if="scope.row.usertype4 === '蓝V'"></i>
                        <i class="type_ico v_orange" v-if="scope.row.usertype4 === '金V'"></i>
                        <i class="type_ico v_pink" v-if="scope.row.usertype4 === '微博女郎'"></i>
                        <i class="type_ico v_talent_show" v-if="scope.row.usertype4 === '达人'"></i>
                        <i class="type_ico" v-else></i>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count4" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_weibo[4].key" header-align="center" v-if="detail.media.media_weibo.length >= 5">
                    <el-table-column prop="name5" label="博主" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name5 }}</span>
                        <i class="type_ico v_red" v-if="scope.row.usertype5 === '红V'"></i>
                        <i class="type_ico v_blue" v-if="scope.row.usertype5 === '蓝V'"></i>
                        <i class="type_ico v_orange" v-if="scope.row.usertype5 === '金V'"></i>
                        <i class="type_ico v_pink" v-if="scope.row.usertype5 === '微博女郎'"></i>
                        <i class="type_ico v_talent_show" v-if="scope.row.usertype5 === '达人'"></i>
                        <i class="type_ico" v-else></i>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count5" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_weibo[5].key" header-align="center" v-if="detail.media.media_weibo.length >= 6">
                    <el-table-column prop="name6" label="博主" align="center">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name6 }}</span>
                        <i class="type_ico v_red" v-if="scope.row.usertype6 === '红V'"></i>
                        <i class="type_ico v_blue" v-if="scope.row.usertype6 === '蓝V'"></i>
                        <i class="type_ico v_orange" v-if="scope.row.usertype6 === '金V'"></i>
                        <i class="type_ico v_pink" v-if="scope.row.usertype6 === '微博女郎'"></i>
                        <i class="type_ico v_talent_show" v-if="scope.row.usertype6 === '达人'"></i>
                        <i class="type_ico" v-else></i>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count6" label="条数" align="center"></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
            <!--@end 关键微博博主 -->
            <!-- 关键微信公众号 -->
            <el-card class="box-card mar-t-20 bzbj">
              <h3 class="mod-title">关键微信公众号 <el-tooltip class="item" effect="dark" content="监测时间段内，相关微信文章数量较多且影响力较大的微信公众号" placement="bottom"><i class="iconfont icon-tishi"></i></el-tooltip></h3>
              <div class="mar-t-20">
                <el-table :data="wxData" style="width: 100%">
                  <el-table-column :label="detail.media.media_wechat[0].key" header-align="center" v-if="detail.media.media_wechat.length >= 1">
                    <el-table-column prop="name1" label="公众号" align="center">
                    </el-table-column>
                    <el-table-column prop="count1" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_wechat[1].key" header-align="center" v-if="detail.media.media_wechat.length >= 2">
                    <el-table-column prop="name2" label="公众号" align="center">
                    </el-table-column>
                    <el-table-column prop="count2" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_wechat[2].key" header-align="center" v-if="detail.media.media_wechat.length >= 3">
                    <el-table-column prop="name3" label="公众号" align="center">
                    </el-table-column>
                    <el-table-column prop="count3" label="条数" align="center"></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
              <div class="mar-t-20" v-if="detail.media.media_wechat.length >= 4">
                <el-table :data="wxData" style="width: 100%">
                  <el-table-column :label="detail.media.media_wechat[3].key" header-align="center">
                    <el-table-column prop="name4" label="公众号" align="center">
                    </el-table-column>
                    <el-table-column prop="count4" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_wechat[4].key" header-align="center" v-if="detail.media.media_wechat.length >= 5">
                    <el-table-column prop="name5" label="公众号" align="center">
                    </el-table-column>
                    <el-table-column prop="count5" label="条数" align="center"></el-table-column>
                  </el-table-column>
                  <el-table-column :label="detail.media.media_wechat[5].key" header-align="center" v-if="detail.media.media_wechat.length >= 6">
                    <el-table-column prop="name6" label="公众号" align="center">
                    </el-table-column>
                    <el-table-column prop="count6" label="条数" align="center"></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
            <!--@end 关键微信公众号 -->
          </div>
          <!-- @end传播内容比较 -->
        </div>
      </div>
    </div>
  </el-container>
</template>
<script>
  import {parseTime, globalMediaColor, globalCreateChart} from '@/utils/helpers.js';
  import { getDetail } from "@/api/compare";

  export default {
    data() {
      return {
        cond: {},
        detail: {},
        loading: true,
        wbData: [],
        wxData: []
      };
    },
    mounted() {
      this.loadData()
    },
    methods: {
      loadData() {
        const id = this.$route.query.id
        if (!id) {
          window.location.href = "/"
          return false
        }
        this.loading = true
        getDetail({id: id}).then(res => {
          if (res.data.state) {
            const { cond, data } = res.data.data
            this.cond = cond
            this.detail = data
            this.loading = false
            this.handleData()

            this.$nextTick(() => {
              this.createChart()
            })
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(err => {
          this.loading = false
          this.$message.error('获取比较报告失败，服务错误')
        })
      },
      handleData() {
        const _this = this
        this.detail.media.media_weibo.map((item, index) => {
          item.data.map((sub_item, sub_index) => {
            if (_this.wbData[sub_index] === undefined) {
              _this.wbData[sub_index] = {}
              _this.wbData[sub_index][`count${index + 1}`] = sub_item.count
              _this.wbData[sub_index][`name${index + 1}`] = sub_item.name
              _this.wbData[sub_index][`percent${index + 1}`] = sub_item.percent
              _this.wbData[sub_index][`usertype${index + 1}`] = sub_item.usertype
            } else {
              _this.wbData[sub_index][`count${index + 1}`] = sub_item.count
              _this.wbData[sub_index][`name${index + 1}`] = sub_item.name
              _this.wbData[sub_index][`percent${index + 1}`] = sub_item.percent
              _this.wbData[sub_index][`usertype${index + 1}`] = sub_item.usertype
            }
          })
        })
        this.detail.media.media_wechat.map((item, index) => {
          item.data.map((sub_item, sub_index) => {
            if (_this.wxData[sub_index] === undefined) {
              _this.wxData[sub_index] = {}
              _this.wxData[sub_index][`count${index + 1}`] = sub_item.count
              _this.wxData[sub_index][`name${index + 1}`] = sub_item.name
              _this.wxData[sub_index][`percent${index + 1}`] = sub_item.percent
            } else {
              _this.wxData[sub_index][`count${index + 1}`] = sub_item.count
              _this.wxData[sub_index][`name${index + 1}`] = sub_item.name
              _this.wxData[sub_index][`percent${index + 1}`] = sub_item.percent
            }
          })
        })
      },
      createChart() {
        this.lineChart('info', this.detail.count.info, '#258FFF')
        this.lineChart('neg-info', this.detail.count.neg_info, '#F68A7A')
        this.trendChart('info-trend', this.detail.count.info_trend)
        this.trendChart('neg-info-trend', this.detail.count.neg_info_trend)
        this.wordChart()
        this.mapChart()
      },
      mapChart() {
        let elements = window.document.getElementsByClassName('map-chart')
        for (let i = 0; i < elements.length; i++) {
          let data = this.detail.media.media_distributed[i]
          let seriesData = []
          data.data.map((item) => {
            seriesData.push({
              name: item.name,
              value: item.count,
              itemStyle: {
                color: globalMediaColor(item.name)
              }
            })
          })
          let option = {
            tooltip : {
              trigger: 'item',
              formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            series : [
              {
                name: '来源分布',
                type: 'pie',
                radius : '40%',
                center: ['50%', '55%'],
                data: seriesData,
                itemStyle: {
                  emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
          let chart = globalCreateChart(elements[i], option);
          window.addEventListener("resize", () => {chart.resize();});
        }
      },
      wordChart() {
        let elements = window.document.getElementsByClassName('word_cloud')
        for (let i = 0; i < elements.length; i++) {
          let data = this.detail.content.words[i]
          let seriesData = []
          data.data.map((row) => {
            seriesData.push({name: row.word, value: row.count});
          })
          let option = {
            tooltip: {
              trigger: 'item'
            },
            series: [{
              name: '信息数',
              type: 'wordCloud',
              shape: 'circle', // circle cardioid diamond square triangle-forward triangle
              left: 'center',
              top: '20%',
              width: '60%',
              height: '70%',
              right: null,
              bottom: null,
              sizeRange: [12,30],
              rotationRange: [-90, 90],
              rotationStep: 45,
              hape: 'pentagon',
              gridSize: 8,
              drawOutOfBound: false,
              textStyle: {
                normal: {
                  fontFamily: 'sans-serif',
                  //fontWeight: 'bold',
                  color: function () {
                    // Random color
                    let color = ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e']
                    let index = Math.floor((Math.random()*color.length))
                    return color[index]
                  }
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              data: seriesData
            }]
          }
          let chart = globalCreateChart(elements[i], option);
          window.addEventListener("resize", () => {chart.resize();});
        }
      },
      lineChart(id, data, color) {
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:"20px",
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: data.legends
          },
          series: [
            {
              name: '信息量',
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: color,
                  barBorderRadius:[10, 10, 10, 10]
                }
              },
              type: 'bar',
              data: data.data
            }
          ]
        };
        let element = window.document.getElementById(id);
        let chart = globalCreateChart(element, option);
        window.addEventListener("resize", () => {chart.resize();});
      },
      trendChart(id, data) {
        let seriesData = []
        data.legends.map((v, index) => {
          seriesData.push({
            name: v,
            smooth: true,
            type: 'line',
            data: data.data[index]
          })
        })
        data.keys = data.keys.map((date) => {
          return parseTime(new Date(window.$moment(date).valueOf()), 'y-m-d')
        })
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: data.legends,
            type: 'scroll',
            orient: 'horizontal',
            right:"0",
            top: "0",
            left:"0",
            itemWidth:10,
            itemHeight:10,
            itemGap:10,
            icon: "circle",
            pageIconColor:"#555DFE"
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          grid: {
            left: '3%',
            right: '4%',
            top: '35px',
            width:"90%",
            bottom: '0',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.keys
          },
          yAxis: {
            type: 'value'
          },
          series: seriesData
        }
        let element = window.document.getElementById(id);
        let chart = globalCreateChart(element, option);
        window.addEventListener("resize", () => {chart.resize();});
      }
    }
  };
</script>
<style scoped>
.jpbj >>> .el-card__body{padding: 0;}
.banner{text-align: center;line-height: 60px;height: 200px; background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;color: #fff;margin: 0 auto;width: 1380px;position: relative;}
.banner .img{position: absolute;left:100px;top:50px;z-index: 10;height: 60px;}
.banner h1{font-size: 30px;line-height:80px;padding-top:30px;}
.banner span{padding: 0 30px;font-size: 22px;color:rgba(255,255,255,.79);}
.jpbj{background:#fff;width: 1380px;margin:0 auto;padding-bottom:30px;box-shadow:15px 0 15px -15px rgba(194,194,194,0.4), -15px 0 15px -15px rgba(194,194,194,0.4);}
.jpbj h1{font-size: 30px;line-height:80px;padding-top:30px;color: #000;}
.jpbj p{font-size: 20px;line-height: 40px;color:rgba(0,0,0,.8);}
/*比较总览*/
.jpbj .icon_list{font-size: 14px;text-align: center;padding-bottom: 30px;}
.jpbj .icon_list .tit{font-weight:600;line-height:60px;vertical-align: middle;display: flex;justify-content: center;align-items: center;}
.jpbj .icon_list .tit img{float:left;margin-top:-2px;}
.jpbj .icon_list .tit span{padding-left: 10px;font-size: 16px;color:#000;float:left;}
.jpbj .icon_list{line-height: 30px;}
.jpbj .icon_list strong{font-size: 18px;line-height:40px;color: #3f9af2;}
.jpbj .icon_list .fw-b{font-size: 18px;}
.box-card{padding: 20px 0;}
.yqbj h4{border-left: 3px solid #409cf3;padding-left: 15px;margin-left: 20px;}
.w-50{width:49%;margin:20px 1% 0 0;}
/*热门关键词*/
.jzxx2{width:100%;text-align: center;clear: both;margin-top: 10px;padding-bottom: 20px;}
.echarts-box .mod-list li{line-height: 30px;height:30px;}
.echarts-box .mod-list .mod-list-title1{text-align: left;}
/* 上升关键词 */
.ssgjc .num-tit{text-align: left;width:40px;}
.ssgjc .num{margin-right: 22px;}
.ssgjc .mod-list-title2{text-align: left;width:48%;}
.ssgjc .tag{width:17%;padding: 5px;}
.ssgjc .mod-list .mod-list-title1{width:100px;text-align:left;}
.ssgjc >>> .el-progress{width:30%;}
/* 高频正面词 */
.gpzmc .mod-list-title2{width:68%;}
.gpzmc >>> .el-progress{width:39%;}
/*关键词组*/
.gjcz{width: 110px; height: 30px;margin: 10px auto;text-align: center;line-height: 30px;font-weight:600;font-size: 12px;background-color: #ededed;color: #000000;opacity: 0.85;border-radius: 5px;clear: both;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
/* 焦点信息 */
.jdxxbj li {height: auto;padding: 5px 0;}
.jdxxbj li:not(:last-child)::after {content: "";border-bottom: 2px solid rgba(217, 217, 217, 0.7);width: 100%;overflow: hidden;height: 2px;display: block;}
.jdxxbj .num{margin-right:15px;}
.jdxxbj >>> .mg,.jdxxbj >>> .fmg{margin-top: 5px;}
.jdxxbj >>> .mg span,.jdxxbj >>> .fmg span{float:none;}
.jdxxbj .mod-list-title2{white-space: nowrap;text-overflow:ellipsis;width:70%;}
.jdxxbj .zb {float: left;font-weight:600;color: #2a67b3;}
.jdxxbj p span {padding-right: 20px;font-size: 12px;}
.jdxxbj p span strong{float: left;}
.jdxxbj p span:nth-child(3) a{float:left;max-width: 240px;width: auto;white-space: nowrap;text-overflow:ellipsis;overflow: hidden;}
.jdxxbj p a {float: none;}
/* 关键微博博主比较 */
.bzbj >>> .is-group > tr:nth-child(1) th div{color:#fff;font-size: 14px;}
h2,.bzbj >>> .is-group>tr:nth-child(2) th{color: #414141;}
.bzbj >>> .is-group>tr th:nth-child(1){background: #84a3f6;}
.bzbj >>> .is-group>tr th:nth-child(2){background: #ebab61;}
.bzbj >>> .is-group>tr th:nth-child(3) {background: #94cbb1;}
.bzbj >>> .el-table thead.is-group tr:nth-child(2) th {background: none;}
.jpbj >>> .el-tabs__header{margin-top:0;}
.jpbj >>> .el-tabs__header .el-tabs__item{font-weight:600;}
.jdxx_list{min-height: 488px;overflow: hidden;}
.mod-echarts-right{height:300px;}
.type_ico{width: 16px;height: 16px;z-index: 10;position: absolute;bottom: 10px;right: 10px;background-repeat: no-repeat;background-size: 100% 100%;-moz-background-size: 100% 100%;}
</style>
